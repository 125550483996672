import React from 'react';
import { Link } from 'react-router-dom';
import { useEmployee } from '../hooks/useEmployee';
import Shimmer from '../shared/Shimmer';

export default function MiniEmployeeProfile({ email }: { email: string }) {
  const { employee, loading } = useEmployee(email)
  if (loading || !email) {
    return <div>
      <Shimmer width='160px' height='160px' borderRadius='0' />
      <Shimmer height='20px' />
    </div>
  }
  return (
    <div>
      <img src={employee?._links.photo.href} style={{ width: '160px' }} />
      <h3 style={{ margin: '0' }}>
        <Link to={`/employee/${employee?.email}`}>{employee?.name}</Link>
      </h3>
    </div>
  )
}