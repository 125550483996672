import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from '@cimpress/react-components';
import { Link } from 'react-router-dom';

export function ColumnarCard({ children, columnSize, headerLink, header, shuffleLink }) {
  const headerElement = headerLink ? <Link to={headerLink}>{header}</Link> : header;

  return (
    <Card header={headerElement} id="members" className="org-card">
      <div style={{ margin: 'auto', width: '90%', display: 'grid', gridTemplateColumns: `repeat(${columnSize}, auto)` }}>
        {children}
      </div>
      {shuffleLink ? (
        <div>
          <Link to={shuffleLink}>Shuffle</Link>
        </div>
      ) : (
        <div />
      )}
    </Card>
  );
}

export default withTranslation('columnarCard')(ColumnarCard);
