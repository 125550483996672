import React from 'react';
import { withTranslation } from 'react-i18next';
import { Breadcrumbs, BreadcrumbItem } from '@cimpress/react-components';

// TODO fix navigation

export function Navigation({ navSteps = [] }) {
  return (
    <Breadcrumbs>
      {navSteps.map((navStep) => (
        <BreadcrumbItem key={navStep.name}>
          <a href={navStep.link}>{navStep.name}</a>
        </BreadcrumbItem>
      ))}
    </Breadcrumbs>
  );
}

export default withTranslation('navigation')(Navigation);
