import useSWR from 'swr'
import { fetchEmployee } from '../services/orgchart'

export function useEmployee(email: string) {
  const { data, error, isLoading } = useSWR(email ? `/v0/employees/${email}` : null, () => fetchEmployee(email));

  let employee;
  if (data) {
    const { id, ...rest } = data
    employee = {
      email: id,
      photo: data._links.photo?.href,
      domain: data._links.domain?.id,
      squad: data._links.squad?.id,
      ...rest
    }
  }

  return {
    employee,
    loading: isLoading,
    error,
  }
}
