import { connect } from 'react-redux';
import Edges from '../presentational/Edges';
import { setNeighbors } from '../actions';

// It maps necessary state attributes and dispatchable actions to props for the
// presentational components to consume.

const mapStateToProps = ({ networkReducer: { neighbors } }) => ({
  neighbors,
});

const mapDispatchToProps = (dispatch) => ({
  changeNeighbors: (option) => dispatch(setNeighbors(option)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edges);
