import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { get, orderBy } from 'lodash';
import tableMaker from '../shared/tablemaker';
import roundHundreths from '../shared/roundhundredths';
import { fetchDomainToDomainConnectivity } from '../services/channelgraph';

export const SlackDomainToDomain = ({ domain }) => {
  const [data, setData] = useState({ contents: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchDomainToDomainConnectivity(domain.toLowerCase());
        setLoading(false);

        setData({
          contents: orderBy(get(result, '_embedded.connectivity', []), (domainConnections) => domainConnections.connectionPct, 'desc'),
        });
      } catch (err) {
        setLoading(false);
      }
    };

    if (domain) {
      fetchData();
    }
  }, [domain]);

  const { columnDefinition, render } = tableMaker({
    data,
    columnNames: ['targetDomain', 'connectionPct'],
    failedMessage: 'No slack connectivity found',
    loading,
  });

  columnDefinition.targetDomain = {
    display: 'Domain',
    link: ({ value }) => ({
      href: `/domain/${value}`,
    }),
  };

  columnDefinition.connectionPct = {
    toolTip: 'The percentage of possible connections that are active between these domains',
    display: "Domain's Connectivity",
    link: ({ row, value }) => ({
      // eslint-disable-next-line max-len
      href: `/network?defaultState=false&partition=domain&exceptions=${domain.toLowerCase()}&exceptions=${row.original.targetDomain.toLowerCase()}&neighbors=intersecting`,
      value: roundHundreths(value),
    }),
  };

  return render();
};

export default withTranslation('slackDomainToDomain')(SlackDomainToDomain);
