import React from 'react';
import HeaderComponent from '@cimpress-technology/react-platform-header';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { auth } from './auth';
import Download from './Download';

function AppHeader({ simple }) {
  const appTitle = (
    <a href="/">
      <h2>Org Chart</h2>
    </a>
  );

  const header = (
    <HeaderComponent
      appTitle={appTitle}
      profile={auth.getProfile()}
      isLoggedIn={auth.isLoggedIn()}
      onLogInClicked={() => auth.login({ nextUri: window.location.pathname })}
      onLogOutClicked={() => auth.logout('/')}
      mailToAddress="CTFulfillmentNetworkingSquad@cimpress.com"
      language={i18n.languages[0]}
      appLinks={[
        {
          id: 'home',
          content: <Link to="/">Home</Link>,
        },
        {
          id: 'me',
          content: <Link to="/employee/me">My Profile</Link>,
        },
        {
          id: 'search',
          content: <Link to="/search">Search</Link>,
        },
        {
          id: 'memberships',
          content: <Link to="/memberships">Memberships</Link>,
        },
        {
          id: 'network',
          content: <Link to="/network">Network</Link>,
        },
        {
          id: 'download',
          content: <Download />,
        },
      ]}
    />
  );

  return !simple ? header : <div />;
}

export default AppHeader;
