import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import P from 'bluebird';
import { Spinner } from '@cimpress/react-components';
import { withTranslation } from 'react-i18next';
import LoginError from './LoginError';
import Header from '../shared/AppHeader';
import { auth } from '../shared/auth';
import { SimpleHider } from '../shared/SimpleHider';
import { MessageToaster } from '../messagetoaster/MessageToaster';
import useMessageToaster from '../messagetoaster/useMessageToaster';
import { MessageToasterProvider } from '../messagetoaster/MessageToasterContext';
import { Navigation } from '../navigation/Navigation';

function AppShell({ t, children }) {
  const [isUserAuthenticating, setIsUserAuthenticating] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(null);
  const location = useLocation();
  const hide = location.pathname === '/network' || location.search.includes('simple=true');

  const toasterProps = useMessageToaster();

  async function authenticateUser() {
    const options = {
      nextUri: window.location.pathname + window.location.search,
    };
    try {
      await P.resolve(auth.ensureAuthentication(options));
      setIsUserAuthenticating(false);
    } catch (error) {
      setAuthenticationError(error.message);
      setIsUserAuthenticating(false);
    }
  }

  useEffect(() => {
    authenticateUser();
  }, []);

  if (isUserAuthenticating) {
    return (
      <div className="re-appShell re-appShell--authenticating">
        <Spinner size="large" />
      </div>
    );
  }

  if (!auth.isLoggedIn() && authenticationError) {
    return <LoginError t={t} authenticationError={authenticationError} />;
  }

  if (hide) {
    return <>{React.cloneElement(children, { hide })}</>;
  }

  return (
    <div className="re-appShell">
      <SimpleHider>
        <Header t={t} />
      </SimpleHider>
      <div className="re-appShellLowerWrapper">
        <MessageToaster {...toasterProps} />
        <MessageToasterProvider value={{ renderMessageToaster: toasterProps.renderToaster }}>
          <div className="re-appShellLowerWrapper__mainContent">
            <Navigation />
            {children}
          </div>
        </MessageToasterProvider>
      </div>
    </div>
  );
}

export default withTranslation('appShell')(AppShell);
