import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import SimpleDomain from './SimpleDomain';
import FullDomain from './FullDomain';

/**
 * This component acts a bit like an old-style redux container loading a lot of data from state
 * but it also switches between the simple and the complex view of the domain
 */
export function Domain() {
  const { domain } = useParams();
  const [searchParams] = useSearchParams();
  const { simple } = Object.fromEntries(searchParams);

  if (simple) {
    return <SimpleDomain id={domain} />;
  }
  return <FullDomain id={domain} />;
}

export default withTranslation('domain')(Domain);
