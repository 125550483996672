import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@cimpress/react-components';
import { get } from 'lodash';
import { People } from 'react-bootstrap-icons';
import tableMaker from '../shared/tablemaker';
import roundHundreths from '../shared/roundhundredths';
import { fetchConnectivity } from '../services/channelgraph';

export const SlackConnectivity = () => {
  const [data, setData] = useState({ contents: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await fetchConnectivity();
      setLoading(false);

      setData({
        contents: get(result, '_embedded.connectivity', []),
      });
    };

    fetchData();
  }, []);

  const personalconnectivityInfo = ({ relationType, personalconnectivity, missingConnections }) => {
    const connectivity = `${roundHundreths(personalconnectivity)}%`;
    if (relationType === 'intra-tribe' && missingConnections.length) {
      return (
        <Tooltip direction="top" contents={`You are missing the following connections: \n ${missingConnections}`}>
          {connectivity}
          <People size={18} />
        </Tooltip>
      );
    }
    return connectivity;
  };

  const { columnDefinition, render } = tableMaker({
    data,
    columnNames: ['relationType', 'personalconnectivity', 'roleConnectivity', 'domainConnectivity'],
    failedMessage: 'No slack data available',
    loading,
  });

  columnDefinition.relationType = {
    toolTip: 'intra-domain: measures your connections within your domain, vs inter-domain which is those outside your domain',
    cell: (row) => (row.original.relationType === 'inter-domain' ? 'Outside your domain' : 'Within your domain'),
    display: 'Relationship Type',
  };

  columnDefinition.personalconnectivity = {
    toolTip: 'The percentage of people you are connected to in the organization',
    display: 'Your Connectivity',
    cell: (row) => personalconnectivityInfo(row.original),
  };

  columnDefinition.roleConnectivity = {
    toolTip: 'The average percentage of people that people in your role are connected to',
    display: "Your Role's Average Connectivity",
    cell: (row) => `${roundHundreths(row.original.roleConnectivity)}%`,
  };

  columnDefinition.domainConnectivity = {
    toolTip: 'The percentage of connections made by your domain',
    display: "Your Domains's Connectivity",
    cell: (row) => `${roundHundreths(row.original.domainConnectivity)}%`,
  };

  return render();
};

export default withTranslation('slackConnectivity')(SlackConnectivity);
