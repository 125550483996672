/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from '@cimpress/react-components';
import { useNavigate } from 'react-router-dom';
import { useSquad } from '../hooks/useSquad';
import Shimmer from '../shared/Shimmer';

/**
 * This displays a squad's most basic information on the front page as though it was a domain
 */
export function SquibeCard({ id }) {
  const navigate = useNavigate();
  const { squad, loading } = useSquad(id)
  const goToSquad = () => navigate(`/squad/${id}`)

  if (loading) {
    return <Card as="button" header={id} className="org-card domainCard" onClick={goToSquad}>
      <div className="imageHolder" />
      <Shimmer height='16px' />
    </Card>
  }

  const { name, members } = squad;

  return (
    <Card as="button" header={name} className="org-card domainCard" onClick={goToSquad} >
      <div className="imageHolder" />
      <p className="card-text">
        Members:
        {members.length}
      </p>
    </Card>
  );
}

export default withTranslation('squibeCard')(SquibeCard);
