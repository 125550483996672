import React from 'react';
import { Trans } from 'react-i18next';

function LoginError({ t, authenticationError }) {
  return (
    <div className="re-appShell re-appShell--authenticationFailure">
      <div>
        <h1>{t('authErrorHeader')}</h1>
        <div className="re-appShell__authenticationError">{authenticationError}</div>
        <h3>{t('authErrorTryAgain')}</h3>
        <Trans i18nKey="authErrorContact" parent="h3">
          If this issue continues, please
          <a href="mailto:FulfillerOnboardingSupport@cimpress.com"> contact support </a>
          for assistance.
        </Trans>
      </div>
    </div>
  );
}

export default LoginError;
