import randomColor from 'randomcolor';
import { scanPartitionable } from './valueschema';

const fixedColors = [
  '#ff679f',
  '#ff7523',
  '#110EFC',
  '#979eff',
  '#00c787',
  '#00dbff',
  '#006471',
  '#FF7CFF',
  '#90FD1E',
  '#008080',
  '#e6beff',
  '#F3FC35',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#fabebe',
  '#9a6324',
  '#ffffff',
  '#000000',
];

const getColors = (count) => {
  const retVal = fixedColors.slice(0, count);
  while (retVal.length < count) {
    retVal.push(randomColor());
  }
  return retVal;
};

export default function createColorSchemes({ svg, values }) {
  const createColorScheme = ({ valueType, generate = false }) => {
    const colorScheme = {};

    if (generate) {
      const newColors = getColors(values[valueType].size);
      values[valueType].forEach((value) => {
        colorScheme[value] = newColors.pop();
      });
    } else {
      values[valueType].forEach((value) => {
        if (value) {
          colorScheme[value] = svg.querySelector(`circle[${valueType}='${value}']`).getAttribute('fill');
        } else {
          colorScheme.null = svg.querySelector(`circle:not([${valueType}])`).getAttribute('fill');
        }
      });
    }
    return colorScheme;
  };

  const retVal = {};

  scanPartitionable(({ valueTypeName, valueType }) => {
    retVal[valueTypeName] = createColorScheme({
      svg,
      valueType: valueTypeName,
      values,
      generate: !valueType.initial,
    });
  });

  return retVal;
}
