import React from 'react';
import { Route, Routes, Navigate, BrowserRouter, useSearchParams } from 'react-router-dom';
import { GlobalStyles } from '@cimpress/react-components';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

import Employee from './employee/Employee';
import RandomEmployee from './employee/RandomEmployee';
import Search from './employee/Search';
import AppShell from './appshell/AppShell';
import Network from './network/presentational/Network';
import Domains from './domains/Domains';
import Domain from './domains/Domain';
import Squad from './squads/Squad';
import Roles from './roles/Roles';
import Membership from './memberships/Membership';
import { auth } from './shared/auth';
import { MembershipTypes } from './memberships/MembershipTypes';

function AppRoutes() {
  const [search] = useSearchParams();

  const getCurrentEmail = () => {
    const profile = auth.getProfile();
    return profile ? profile[['https://claims.cimpress.io/email']] : '';
  };

  return (
    <Routes>
      <Route path="/" element={<Domains />} />

      <Route path="/employee/me" element={<Navigate to={`/employee/${getCurrentEmail()}?${search}`} />} />
      <Route path="/employee/:email" element={<Employee />} />
      <Route path="/employee/random" element={<RandomEmployee />} />

      <Route path="/domains" element={<Domains />} />
      <Route exact path="/domain/:domain" element={<Domain />} />

      <Route path="/squad/:squad" element={<Squad />} />

      <Route path="/role/:roleName" element={<Roles />} />

      <Route path="/memberships" element={<MembershipTypes />} />
      <Route path="/memberships/:membership" element={<Membership />} />

      <Route path="/search" element={<Search />} />

      <Route path="/network" element={<Network />} />
    </Routes>
  );
}

export default function rootRender({ root, store }) {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <GlobalStyles />
            <AppShell>
              <AppRoutes store={store}/>
            </AppShell>
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>,
  );
}
