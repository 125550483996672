import React, { useState } from 'react';
import { Tooltip, Toggle } from '@cimpress/react-components';
import { SketchPicker } from 'react-color';

export function ColorSubject({ subject, color, active, onColorChange, onActiveChange, className }) {
  const [data, setData] = useState({ showPicker: false });
  const colorPicker = <SketchPicker color={color} onChangeComplete={(newColor) => onColorChange({ color: newColor.hex, subject })} />;
  return (
    <table className={className}>
      <tr>
        <td>
          <Tooltip
            direction="top"
            variety="popover"
            contents={colorPicker}
            onClickOutside={() => data.showPicker && setData({ ...data, showPicker: false })}
            show={data.showPicker}
          >
            <button
              type="submit"
              onClick={() => setData({ ...data, showPicker: !data.showPicker })}
              className="btn btn-primary"
              style={{ backgroundColor: color }}
            >
              {' '}
            </button>
          </Tooltip>
        </td>
        <td>{subject}</td>
        <td>
          <Toggle on={active} onClick={() => onActiveChange({ active: !active, subject })} size="sm" />
        </td>
      </tr>
    </table>
  );
}

export default ColorSubject;
