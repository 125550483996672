import React, { useState } from 'react';
import { NavTab, NavTabItem } from '@cimpress/react-components';
import Conditional from './Conditional';
import { getQueryObject, setQueryObject } from './qs';

export default function Tabs({ tabSet, children }) {
  // establishes a state where the default comes out of the query string, or is the first tab
  // in the tabset
  const [selectedTab, setSelectedTab] = useState(getQueryObject().currentTab || tabSet[0].key);

  // gets the content of the tab to render
  const getContent = () => {
    const currentTab = tabSet.find((tuple) => tuple.key === selectedTab);
    if (currentTab.content) {
      return currentTab.content;
    }
    return React.cloneElement(children, { selectedTab });
  };

  // sets the state of the tabs but also writes the tab to the query string
  const changeTab = (newTab) => {
    setSelectedTab(newTab);
    const qs = getQueryObject();
    qs.currentTab = newTab;
    setQueryObject(qs);
  };

  return (
    <div className="tabbedInfo">
      <NavTab vertical={false}>
        {tabSet.map((tab) => (
          <NavTabItem active={selectedTab === tab.key} key={tab.key}>
            <button type="button" onClick={() => changeTab(tab.key)}>
              <Conditional condition={tab.icon}>{tab.icon}</Conditional>
              {tab.name}
            </button>
          </NavTabItem>
        ))}
      </NavTab>
      {getContent()}
    </div>
  );
}
