import useSWR from 'swr'
import { sortBy } from 'lodash'
import { fetchDomains } from '../services/orgchart'

export function useDomains() {
  const { data, error, isLoading } = useSWR('/v0/domains', fetchDomains)

  let all;
  if (data) {
    all = data._embedded.domains;
  }
  const squibes = sortBy(all?.filter(domain => domain._links.type === 'squibe').map(d => d._links), (d) => d.id.toLowerCase())
  const domains = sortBy(all?.filter(domain => domain._links.type !== 'squibe').map(d => d._links), (d) => d.id.toLowerCase())

  return {
    all,
    domains,
    squibes,
    loading: isLoading,
    error,
  }
}