import React from 'react';
import { withTranslation } from 'react-i18next';
import OwnershipPanel from './OwnershipPanel';

export function OrganizationMetaInfo({ organizationName, organizationType }) {
  return (
    <div>
      <div
        className="profile"
        style={{
          marginRight: '10px',
          minWidth: '300px',
          textAlign: 'left',
        }}
      >
        <OwnershipPanel organizationName={organizationName} organizationType={organizationType} />
      </div>
    </div>
  );
}

export default withTranslation('organizationMetaInfo')(OrganizationMetaInfo);
