import { authFetch } from '../shared/auth';

export async function loadSlackActivity() {
  const response = await authFetch(`https://channelgraphserver.fi.cimpress.io/v0/userreports/me/rooms`);
  const data = await response.json();
  return data._embedded.rooms as any[];
}

export async function fetchConnectivity() {
  const response = await authFetch(`https://channelgraphserver.fi.cimpress.io/v0/userreports/me/connectivity`);
  return response.json();
}

export async function fetchTribeConnectivity() {
  const response = await authFetch(`https://channelgraphserver.fi.cimpress.io/v0/userreports/me/tribeconnections`);
  return response.json();
}

export async function fetchDomainConnectivity() {
  const response = await authFetch(`https://channelgraphserver.fi.cimpress.io/v0/userreports/me/domainconnections`);
  return response.json();
}

export async function fetchDomainToDomainConnectivity(domain: string) {
  const response = await authFetch(`https://channelgraphserver.fi.cimpress.io/v0/domainreports/${domain}/connectivity`);
  return response.json();
}

export async function fetchSquadToSquadConnectivity(squad: string) {
  const response = await authFetch(`https://channelgraphserver.fi.cimpress.io/v0/squadreports/${squad}/connectivity`);
  return response.json();
}
