import React, { useEffect, useState } from 'react';
import { QuestionCircleFill } from "react-bootstrap-icons"
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Toggle, Tooltip } from '@cimpress/react-components';
import { fetchOrphanedSoftware } from '../apis/actions';
import tableMaker from '../shared/tablemaker';

export function OrphanedSoftware() {
  const [data, setData] = useState({ sortKey: { key: 'id', desc: -1 }, contents: [] });
  const [orphanedSoftwares, setOrphanedSoftwares] = useState([]);
  const [loading, setLoading] = useState(false);
  const [includeDeprecated, setIsIncludeDeprecated] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await fetchOrphanedSoftware();
      setLoading(false);
      setOrphanedSoftwares(get(result, '_embedded.orphans', []));
    };

    fetchData();
  }, []);

  useEffect(() => {
    setData((value) => ({
      sortKey: value.sortKey,
      contents: orphanedSoftwares,
    }));
  }, [orphanedSoftwares]);

  useEffect(() => {
    setData((value) => ({
      sortKey: value.sortKey,
      contents: includeDeprecated ? orphanedSoftwares : orphanedSoftwares.filter(software => !software.deprecated),
    }));
  }, [includeDeprecated, orphanedSoftwares]);

  const onToggle = () => setIsIncludeDeprecated(!includeDeprecated);

  const { columnDefinition, render } = tableMaker({
    data,
    columnNames: ['id', 'name', 'email', 'type'],
    failedMessage: 'No orphaned software found',
    loading,
    sort: setData
  });
  columnDefinition.name.link = ({ row }) => ({
    href: `${get(row, 'original._links.self.href')}`,
  });

  const table = render();

  return <div>
    <div className="orphan-info">
      <QuestionCircleFill color="green" size={20} />
      <a href="https://cimpress-support.atlassian.net/wiki/spaces/PCD/pages/15450734655/Orphaned+Software">
        Learn more about orphaned software and how to fix it
      </a>
      &nbsp;
      <Tooltip direction='top' contents={includeDeprecated ? 'Included Deprecated' : 'Excluded Deprecated'}>
        <Toggle on={includeDeprecated} onClick={onToggle} size='sm' />
      </Tooltip>
    </div>
    {table}
  </div>
}

export default withTranslation('orphanedSoftware')(OrphanedSoftware);
