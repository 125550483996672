module.exports = (colors) => {
  const rgbs = colors.map((color) => ({
    r: parseInt(color.substring(0, 2), 16),
    g: parseInt(color.substring(2, 4), 16),
    b: parseInt(color.substring(4, 6), 16),
  }));

  const avgRgb = {
    r: Math.round(rgbs.map((rgb) => rgb.r).reduce((a, b) => a + b) / colors.length),
    g: Math.round(rgbs.map((rgb) => rgb.g).reduce((a, b) => a + b) / colors.length),
    b: Math.round(rgbs.map((rgb) => rgb.b).reduce((a, b) => a + b) / colors.length),
  };

  const pad = (str) => (str.length < 2 ? `0${str}` : str);

  return pad(avgRgb.r.toString(16)) + pad(avgRgb.g.toString(16)) + pad(avgRgb.b.toString(16));
};
