import { authFetch, handleErrors } from "../shared/auth";
import { DomainResponse, DomainsResponse, EmployeeResponse, SquadResponse, SoftwareResponse, EmployeeSearchResponse, MembershipResponse } from "./responseTypes";

export async function fetchDomains() {
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/domains`);
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as DomainsResponse;
}

export async function fetchDomainByName(domainName: string) {
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/domains/${encodeURIComponent(domainName)}`);
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as DomainResponse;
}

export async function fetchSquadByName(squadName: string) {
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/squads/${encodeURIComponent(squadName)}`);
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as SquadResponse;
}

export async function fetchMembership(membershipName: string) {
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/memberships/${encodeURIComponent(membershipName)}`);
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as MembershipResponse;
}



export async function fetchEmployee(email: string) {
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/employees/${email}`);
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as EmployeeResponse;
}

export async function searchEmployees(searchParams: { [key: string]: string }) {
  const query = new URLSearchParams(searchParams).toString()
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/employees/?${query}`);
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as EmployeeSearchResponse;
}


export async function fetchSoftware(organizationType: string, organizationName: string) {
  const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/software`, { [organizationType]: organizationName, showWarnings: true });
  await handleErrors(response);
  const responseBody = (await response.json());
  return responseBody as SoftwareResponse;
}
