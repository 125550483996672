import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { startCase } from 'lodash';
import EmployeeDetails from '../shared/EmployeeDetails';
import OrgInfo from '../shared/OrgInfo';
import { useEmployeeSearch } from '../hooks/useEmployeeSearch';

export function Roles() {
  const { roleName } = useParams();
  const name = startCase(roleName);
  const { employees } = useEmployeeSearch({ role: roleName })

  return (
    <>
      <OrgInfo name={roleName} />
      <EmployeeDetails members={employees || []} name={name} />
    </>
  );
}

export default withTranslation('roles')(Roles);
