import React from 'react';
import { withTranslation } from 'react-i18next';

export function SimpleHider({ children }) {
  if (window.location.search.includes('simple')) {
    return <div />;
  }
  return children;
}

export default withTranslation('aimpleHider')(SimpleHider);
