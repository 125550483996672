import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { SlackSquadToSquad } from '../slack/SlackSquadToSquad';
import MemberList from '../shared/MemberList';
import Conditional from '../shared/Conditional';
import { OrganizationMetaInfo } from '../software/OrganizationMetaInfo';
import { useSquad } from '../hooks/useSquad';
import { OrgInfo } from '../shared/OrgInfo';

const rolePriority = {
  'squad lead': -2,
  'product manager': -1,
  'individual contributor': 2,
};

export function Squad({ squadId, simple = false }) {
  const params = useParams();

  const { squad, loading } = useSquad(squadId || params.squad)

  if (loading || !squad) {
    return null;
  }

  const { name, id, distributionList, documentation, members } = squad;
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: simple ? '1fr' : 'auto 1fr',
        gap: '24px'
      }}
    >
      <Conditional condition={!simple}>
        <OrganizationMetaInfo organizationName={name} />
      </Conditional>

      <div>
        <OrgInfo name={name} distributionList={distributionList} documentation={documentation?.href} />
        <MemberList rolePriority={rolePriority} members={members} />

        <Conditional condition={!simple}>
          <div style={{ width: '50vw' }}>
            <h2 style={{ paddingTop: '50px' }}>
              {capitalize(name)}
              &apos;s connections to other squads
            </h2>
            <SlackSquadToSquad squad={id} />
          </div>
        </Conditional>
      </div>
    </div>
  );
}

export default withTranslation('squad')(Squad);
