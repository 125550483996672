import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Drawer, Button, ButtonGroup } from '@cimpress/react-components';
import Conditional from '../../shared/Conditional';
import ColorSubject from '../../shared/ColorSubject';
import Partitions from '../container/partitionoptions';
import Edges from '../container/edgeoptions';
import { TextToggle } from './TextToggle';

export function NetworkOptions({ colors, active, changeColor, changeActive, changeActiveAll }) {
  const [data, setData] = useState({ openDrawer: false });

  return (
    <>
      <div className="network-toolbar">
        <button type="submit" className="btn btn-default" onClick={() => setData({ ...data, openDrawer: true })}>
          Subjects
        </button>
        <Partitions />
        <Edges />
        <TextToggle />
      </div>
      <Drawer
        position="left"
        autoResize
        size={0.25}
        show={data.openDrawer}
        className="network-drawer"
        onRequestHide={() => setData({ ...data, openDrawer: false })}
        closeOnClickOutside
        footer={
          <div>
            <button type="submit" className="btn btn-default" onClick={() => setData({ ...data, openDrawer: false })}>
              Close
            </button>
          </div>
        }
      >
        <div className="network-options">
          <Conditional condition={colors !== undefined}>
            <div className="color-list">
              <ButtonGroup type="justified" className="buttons">
                <Button onClick={() => changeActiveAll(true)}>Enable All</Button>
                <Button onClick={() => changeActiveAll(false)}>Disable All</Button>
              </ButtonGroup>
              {Object.keys(colors)
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((subject) => (
                  <ColorSubject
                    subject={subject}
                    color={colors[subject]}
                    onColorChange={changeColor}
                    active={active[subject]}
                    onActiveChange={changeActive}
                    key={subject}
                    className="partition-subject"
                  />
                ))}
            </div>
          </Conditional>
        </div>
      </Drawer>
    </>
  );
}

export default withTranslation('network')(NetworkOptions);
