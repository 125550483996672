/* eslint-disable no-shadow */
import React, { useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import SvgViewer from '../../shared/svgviewer';
import { scanActiveElements, activeByAttribute, isEdgeActiveBySelection } from '../activescan';
import midcolor from '../midcolor';

const onOpacity = 1;
const offOpacity = 0.1;

export function NetworkView({ partition, svgData, colors, active, neighbors, highlighted, textState }) {
  const toggleHighlight = () => undefined; // todo restore functionality?
  const svgRef = useRef(null);

  useEffect(() => {
    if (svgRef && svgRef.current) {
      const svg = svgRef.current.querySelector('svg');
      if (svg) {
        svg.querySelectorAll('circle').forEach((node) => {
          const value = node.getAttribute(partition);
          node.setAttribute('fill', colors[value]);
        });

        svg.querySelectorAll('path').forEach((edge) => {
          const values = JSON.parse(edge.getAttribute(partition));
          const nodeColors = values.map((value) => colors[value].replace('#', ''));
          const newColor = midcolor(nodeColors);
          edge.setAttribute('stroke', `#${newColor}`);
        });
      }
    }
  }, [partition, colors]);

  useEffect(() => {
    if (svgRef && svgRef.current) {
      const svg = svgRef.current.querySelector('svg');
      if (svg) {
        svg.querySelectorAll('text').forEach((node) => node.setAttribute('opacity', textState ? 1 : 0));
      }
    }
  }, [textState]);

  useEffect(() => {
    if (svgRef && svgRef.current) {
      const svg = svgRef.current.querySelector('svg');
      if (svg) {
        const activeFilter = (edge) =>
          activeByAttribute({
            edgeNodes: JSON.parse(edge.getAttribute(partition)),
            neighbors,
            active,
          });

        scanActiveElements({
          svg,
          isActive: activeFilter,
          edgeModification: ({ node, active }) => node.setAttribute('stroke-opacity', active ? 0.8 : 0.01),
          nodeModification: ({ node, active }) => node.setAttribute('fill-opacity', active ? onOpacity : offOpacity),
          textModification: ({ node, active }) => node.setAttribute('fill-opacity', active ? onOpacity : offOpacity),
        });

        if (highlighted) {
          const applyHighlight = ({ node, active }) => {
            if (active) {
              node.classList.add('highlight');
              node.classList.remove('background');
            } else {
              node.classList.remove('highlight');
              node.classList.add('background');
            }
          };
          scanActiveElements({
            svg,
            isActive: (edge) =>
              isEdgeActiveBySelection({
                svg,
                edge,
                sourceClass: highlighted,
                partition,
                neighbors,
                active,
              }),
            edgeModification: applyHighlight,
            nodeModification: applyHighlight,
            textModification: applyHighlight,
          });

          svg.querySelector(`circle.${highlighted}`).classList.add('highlight');
        } else {
          const removeHighlights = ({ node }) => {
            node.classList.remove('highlight');
            node.classList.remove('background');
          };
          scanActiveElements({
            svg,
            isActive: () => true,
            edgeModification: removeHighlights,
            nodeModification: removeHighlights,
            textModification: removeHighlights,
          });
        }
      }
    }
  }, [highlighted, partition, active, neighbors]);

  return (
    <SvgViewer
      svgData={svgData}
      ref={svgRef}
      onClick={(event) => toggleHighlight({ id: event.target.classList.item(0), email: event.target.getAttribute('email') })}
    />
  );
}

export default withTranslation('networkView')(NetworkView);
