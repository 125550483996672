import React, { useContext } from "react";
import { RenderMessageToasterHandler } from "./useMessageToaster";

const MessageToasterContext = React.createContext<{ renderMessageToaster: RenderMessageToasterHandler }>({
  renderMessageToaster: () => undefined,
});

export const MessageToasterProvider = MessageToasterContext.Provider;
export const MessageToasterConsumer = MessageToasterContext.Consumer;

export default function useMessageToaster() {
  const { renderMessageToaster } = useContext(MessageToasterContext);

  return renderMessageToaster;
}
