import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import '../../scss/network.scss';

import { useDispatch } from 'react-redux';
import NetworkOptions from '../container/networkoptions';
import NetworkView from '../container/networkview';
import NetworkProfile from '../container/networkprofile';
import { loadNetwork } from '../actions';

export function Network() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNetwork());
  }, [dispatch]);

  return (
    <>
      <NetworkOptions />
      <NetworkView />
      <div className="network-profile">
        <NetworkProfile />
      </div>
    </>
  );
}

export default withTranslation('network')(Network);
