export default class EnhancedError extends Error {
  public additionalData?: {
    [key: string]: any;
  };

  public status?: number;

  public statusText?: string;

  constructor(
    message: string,
    status: number | undefined,
    statusText: string | undefined,
    additionalData:
      | {
        [key: string]: any;
      }
      | undefined
  ) {
    super(message);
    this.name = this.constructor.name;
    this.status = status;
    this.statusText = statusText;
    this.additionalData = additionalData;

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}
