import { Table, Tooltip } from '@cimpress/react-components';
import { CheckSquareFill, XSquareFill, CloudDownloadFill } from 'react-bootstrap-icons';
import { CSVLink } from 'react-csv';
import React from 'react';

// method to build the header allowing for an alias or a tooltip
const buildHeader = ({ toolTip, key, display }) => {
  if (toolTip) {
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Tooltip direction="top" contents={toolTip}>
        {display || key}
      </Tooltip>
    );
  }

  return display || key;
};

// build the rendering instructions for a cell which can be directly
// provided by the client, but can also be natively handled by table
// maker itself to create links and boolean representations
const buildCell = ({ cell, link, bool, key }) => {
  if (cell) {
    return cell;
  }

  if (link) {
    return function linkRender(row) {
      // eslint-disable-next-line react/destructuring-assignment
      const value = row.original[key];
      if (value) {
        const linkInfo = link({ value, row });
        linkInfo.text = linkInfo.text || value;
        return <a href={linkInfo.href}>{linkInfo.text}</a>;
      }
      return null;
    };
  }

  if (bool) {
    return function boolRender(row) {
      // eslint-disable-next-line react/destructuring-assignment
      const value = row.original[key];
      return bool({ value, row }) ? <CheckSquareFill color="green" /> : <XSquareFill color="red" />;
    };
  }

  return null;
};

export default function tableMaker({ data, columnNames, failedMessage, loading, sort, downloadName }) {
  const sortable = !!data.sortKey && !!sort;

  // fill out all the column definitions with at least something to start
  const columnDefinition = {};
  columnNames.forEach((name) => {
    columnDefinition[name] = {};
  });

  // the render method will take the column defintion in and transform it into
  // rendered values compatible with the component library's table
  const render = () => {
    // do not over write the data coming into the table, but make a copy instead
    const displayData = data.contents.slice();

    // then iterate through each column in the column definition
    // to build the headers and cells
    const columns = Object.keys(columnDefinition).map((key) => {
      const def = columnDefinition[key];
      return {
        Header: buildHeader({ ...def, key }),
        Cell: buildCell({ ...def, key }),
        accessor: key,
        sortable,
      };
    });

    let tableContent;

    // if its sortable we'll have a little more work to do than if it isn't.
    if (sortable) {
      // manipulate the display data
      displayData.sort(
        (a, b) =>
          (data.sortKey.desc ? -1 : 1) *
          (a[data.sortKey.key] || '').toString().localeCompare(b[data.sortKey.key], undefined, { numeric: true }),
      );

      tableContent = (
        <Table
          columns={columns}
          data={displayData}
          isBordered
          hasHover={false}
          sortedBy={data.sortKey}
          noDataText={loading ? 'Loading...' : failedMessage}
          onSortingRequested={(newSortKey) => {
            sort({ ...data, sortKey: newSortKey });
          }}
        />
      );
    } else {
      tableContent = <Table columns={columns} data={displayData} isBordered hasHover={false} noDataText={loading ? 'Loading...' : failedMessage} />;
    }


    if (downloadName) {
      return (
        <>
          <div className="download">
            <CSVLink data={data.contents} filename={downloadName} target="_blank">Download<CloudDownloadFill size={24} /></CSVLink>

          </div>
          {tableContent}
        </>
      )
    }
    return tableContent;
  };

  return {
    columnDefinition,
    render,
  };
}
