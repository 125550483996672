import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from '@cimpress/react-components';
import { useSelector, useDispatch } from 'react-redux';
import { setText } from '../actions';

export function TextToggle() {
  const textState = useSelector((state) => state.networkReducer.textState);
  const dispatch = useDispatch();

  return <Button onClick={() => dispatch(setText(!textState))}>{`Text: ${textState ? 'On' : 'Off'}`}</Button>;
}

export default withTranslation('partitions')(TextToggle);
