/* eslint-disable max-statements */
import React, { forwardRef, useEffect } from 'react';

export default forwardRef(({ svgData, onClick }, ref) => {
  useEffect(() => {
    const getSvg = () => ref.current.querySelector('svg');

    const getInverse = () => getSvg().getScreenCTM().inverse();

    const getViewBox = () => {
      const values = getSvg()
        .getAttribute('viewBox')
        .split(' ')
        .map((value) => parseFloat(value));
      return {
        x: values[0],
        y: values[1],
        width: values[2],
        height: values[3],
      };
    };

    const setViewBox = ({ x, y, width, height }) => {
      getSvg().setAttribute('viewBox', `${x} ${y} ${width} ${height}`);
    };

    const onMouseUp = () => {
      ref.current.dragging = false;
    };

    const onDrag = (event) => {
      if (ref.current.dragging) {
        const {
          current: { point, startGlobal },
        } = ref;
        point.x = event.clientX;
        point.y = event.clientY;

        const moveGlobal = point.matrixTransform(getInverse());
        const viewBox = getViewBox();
        viewBox.x -= moveGlobal.x - startGlobal.x;
        viewBox.y -= moveGlobal.y - startGlobal.y;
        setViewBox(viewBox);
      }
    };

    const onMouseDown = (event) => {
      const {
        current: { startClient },
      } = ref;
      startClient.x = event.clientX;
      startClient.y = event.clientY;
      ref.current.startGlobal = startClient.matrixTransform(getInverse());
      ref.current.dragging = true;
    };

    // eslint-disable-next-line max-statements
    const onWheel = (event) => {
      event.preventDefault();
      const {
        current: { point },
      } = ref;
      const scaleFactor = 1.05;
      let normalized;
      let delta = event.wheelDelta;

      if (delta) {
        normalized = delta % 120 === 0 ? delta / 120 : delta / 12;
      } else {
        delta = event.deltaY || event.detail || 0;
        normalized = -(delta % 3 ? delta * 10 : delta / 3);
      }

      const scaleDelta = normalized > 0 ? 1 / scaleFactor : scaleFactor;

      point.x = event.clientX;
      point.y = event.clientY;

      const startPoint = point.matrixTransform(getInverse());

      const viewBox = getViewBox();
      viewBox.x -= (startPoint.x - viewBox.x) * (scaleDelta - 1);
      viewBox.y -= (startPoint.y - viewBox.y) * (scaleDelta - 1);
      viewBox.width *= scaleDelta;
      viewBox.height *= scaleDelta;
      setViewBox(viewBox);
    };

    if (ref && ref.current && svgData) {
      ref.current.innerHTML = svgData;
      const svg = getSvg();

      svg.parentElement.addEventListener('wheel', onWheel);
      svg.parentElement.addEventListener('mousedown', onMouseDown);
      svg.parentElement.addEventListener('mousemove', onDrag);
      svg.parentElement.addEventListener('mouseup', onMouseUp);

      svg.querySelectorAll('circle').forEach((node) => {
        node.addEventListener('click', onClick);
      });

      ref.current.point = svg.createSVGPoint();
      ref.current.startClient = svg.createSVGPoint();
      ref.current.startGlobal = svg.createSVGPoint();
      ref.current.dragging = false;

      const viewBox = getViewBox();
      viewBox.width = 8000;
      viewBox.height = 8000;
      setViewBox(viewBox);
    }
  }, [svgData, ref, onClick]);

  return <div ref={ref} className="svgViewer" />;
});
