import useSWR from 'swr';
import { startCase } from 'lodash';
import { fetchSquadByName } from '../services/orgchart';

export function useSquad(squadId: string) {
  const { data, error, isLoading } = useSWR(`/v0/squads/${squadId}`, () => fetchSquadByName(squadId));

  let squad;
  if (data) {
    const { distributionList, id, _embedded: { members }, _links: { documentation } } = data;
    squad = {
      distributionList, name: startCase(id), members, documentation
    }
  }

  return {
    squad,
    loading: isLoading,
    error,
  }
}