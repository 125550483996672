import { authFetch } from '../shared/auth';
import wrap from '../shared/wrap';
import networkHistory from '../networkhistory';

const parser = new DOMParser();

export function loadNetwork() {
  return wrap(async (dispatch) => {
    const url = `https://channelgraphserver.fi.cimpress.io/v0/visualizations/network`;
    const response = await authFetch(url);
    const raw = await response.text();

    const data = {
      raw,
      parsed: parser.parseFromString(raw, 'image/svg+xml'),
    };

    dispatch({
      type: 'LOAD_NETWORK',
      data,
      qs: networkHistory.getQueryString(),
    });
  });
}

export function setPartition(partition) {
  networkHistory.setPartition(partition);
  return (dispatch) => {
    dispatch({
      type: 'SET_PARTITION',
      partition,
    });
  };
}

export function setColor(data) {
  return (dispatch) => {
    dispatch({
      type: 'SET_COLOR',
      data,
    });
  };
}

export function setActive(data) {
  return (dispatch) => {
    networkHistory.setException(data);
    dispatch({
      type: 'SET_ACTIVE',
      data,
    });
  };
}

export function setActiveAll(active) {
  networkHistory.setActiveAll(active);
  return (dispatch) => {
    dispatch({
      type: 'SET_ACTIVE_ALL',
      active,
    });
  };
}

export function setNeighbors(option) {
  return (dispatch) => {
    networkHistory.setNeighbors(option);
    dispatch({
      type: 'SET_NEIGHBORS',
      option,
    });
  };
}

export function setText(textState) {
  return (dispatch) => {
    dispatch({
      type: 'SET_TEXT',
      textState,
    });
  };
}
