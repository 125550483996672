/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button } from '@cimpress/react-components';
import { useEmployeeSearch } from '../hooks/useEmployeeSearch';
import { useEmployee } from '../hooks/useEmployee';

function getRandomNumber(limit) {
  return Math.floor(Math.random() * limit);
}

export function RandomEmployee() {
  const [randomNumber, setRandomNumber] = useState(0)
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { employees } = useEmployeeSearch(params)
  const employeeId = employees ? employees[randomNumber].id : undefined;
  const { employee } = useEmployee(employeeId)

  useEffect(() => {
    if (employees) {
      setRandomNumber(getRandomNumber(employees.length))
    }
  }, [employees])

  if (employee) {
    const { email, name, role, domain, squad } = employee;
    return (
      <div
        style={{
          margin: 'auto',
          width: '512px',
        }}
      >
        <div className="profile large" style={{ position: 'relative', zIndex: 1 }}>
          <img src={employee._links.photoLarge.href} style={{ paddingTop: '34px' }} />
          <span className="profile-overlay">
            <p className="title">
              <Link to={`/employee/${email}`}>{name}</Link>
            </p>
            <p>{role === 'individual contributor' ? 'ind. contributor' : <Link to={`/role/${role}`}>{role}</Link>}</p>
            <p>
              <Link to={`/domain/${domain}`}>{domain}</Link>
            </p>
            <p>
              <Link to={`/squad/${squad}`}>{squad}</Link>
            </p>
          </span>
        </div>

        <div style={{ display: 'block', margin: 'auto', width: '30px' }}>
          <Button
            type="primary"
            onClick={() => setRandomNumber(getRandomNumber(employees.length))}
          >
            Next!
          </Button>
        </div>
      </div >
    );
  }
  return null;
}

export default withTranslation('randomEmployee')(RandomEmployee);
