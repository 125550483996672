import useSWR from 'swr'
import { fetchDomainByName } from '../services/orgchart'

export function useDomain(domainId: string) {
  const { data, error, isLoading } = useSWR(`/v0/domains/${domainId}`, () => fetchDomainByName(domainId));

  let domain;
  if (data) {
    const { distributionList, id, _embedded: { leads, members, squads }, _links: { lead } } = data;
    domain = {
      distributionList, name: id, leads, members, squads, lead
    }
  }

  return {
    domain,
    loading: isLoading,
    error,
  }
}
