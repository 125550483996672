import { connect } from 'react-redux';
import NetworkView from '../presentational/NetworkView';

// It maps necessary state attributes and dispatchable actions to props for the
// presentational components to consume.

const mapStateToProps = ({ networkReducer: { raw, partition, colors, active, neighbors, highlighted, textState } }) => ({
  partition,
  svgData: raw,
  colors: colors[partition],
  active: active[partition],
  neighbors,
  highlighted,
  textState,
});

export default connect(mapStateToProps)(NetworkView);
