import useSWR from 'swr'
import { searchEmployees } from '../services/orgchart'

export function useEmployeeSearch(searchParams: { search: string, domain: string, squad: string, role: string, membership: string, distributionList: string }) {
  const searchIsLongEnough = !searchParams.search || searchParams.search.length > 2;
  const hasSearch = !!Object.values(searchParams).find(v => v) && searchIsLongEnough;
  const { data, error, isLoading } = useSWR(hasSearch ? [`/v0/employees/`, ...Object.values(searchParams)] : null, () => searchEmployees(searchParams), { keepPreviousData: hasSearch });

  let employees;
  if (data) {
    employees = data._embedded.employees;
  }

  return {
    employees,
    loading: isLoading,
    error,
  }
}
