import React from 'react';
import { withTranslation } from 'react-i18next';
import ProfileCard from '../../shared/ProfileCard';
import Conditional from '../../shared/Conditional';

export function NetworkProfile({ show, name, email, photo, role, tribe, squad }) {
  return (
    <Conditional condition={show}>
      <ProfileCard photo={photo} email={email} role={role} name={name} tribe={tribe} squad={squad} />
    </Conditional>
  );
}

export default withTranslation('networkProfile')(NetworkProfile);
