/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from '@cimpress/react-components';
import { useNavigate } from 'react-router-dom';
import Conditional from '../shared/Conditional';
import { useDomain } from '../hooks/useDomain';
import MiniEmployeeProfile from './MiniEmployeeProfile';
import Shimmer from '../shared/Shimmer';

/**
 * This displays the domain's most basic information on the front page
 */
export function DomainCard({ id }) {
  const navigate = useNavigate();
  const { domain, loading } = useDomain(id);

  const goToDomain = () => navigate(`/domain/${id}`)

  if (loading) {
    return <Card as="button" header={id} className="org-card domainCard" onClick={goToDomain}>
      <div className="imageHolder">
        <MiniEmployeeProfile email="" />
      </div>
      <Shimmer height='16px' />
      <Shimmer height='16px' />
    </Card>
  }

  const { name, squads, members, lead } = domain;

  return (
    <Card as="button" header={name} className="org-card domainCard" onClick={goToDomain} >
      <div className="imageHolder">
        <Conditional condition={lead?.id}>
          <MiniEmployeeProfile email={lead?.id} />
        </Conditional>
      </div>
      <div >
        Squads:
        {squads?.length}
      </div>
      <div>
        Members:
        {members.length}
      </div>
    </Card>
  );
}

export default withTranslation('domainCard')(DomainCard);
