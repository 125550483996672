/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconTimeClockCircle } from '@cimpress-technology/react-streamline-icons';
import { colors } from '@cimpress/react-components';
import MembershipTooltip from './MembershipTooltip';
import Conditional from './Conditional';
import { useEmployee } from '../hooks/useEmployee';
import Shimmer from './Shimmer';

export function ProfileCard({ email, name }) {
  const { employee, loading } = useEmployee(email)
  const assumedLocaleFormat = Intl.DateTimeFormat().resolvedOptions().locale;
  const timeZoneStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', color: colors.slate, fontSize: '12px' }

  if (loading || !employee) {
    return <div className="profile" style={{ position: 'relative' }}>
      <Shimmer style={{ height: '150px', borderRadius: '100%', margin: '16px auto 24px' }} />
      <div><Shimmer height='21px' style={{ margin: '1em auto 4px' }} /></div>
      <div><Shimmer /></div>
      <hr style={{ width: '75%', margin: '8px auto' }} />
      <div><Shimmer /></div>
      <div><Shimmer /></div>
      <div><Shimmer /></div>
    </div>
  }

  const { photo, role, domain, squad, timeZone, memberships = [] } = employee;

  return (
    <div className="profile">
      <img src={photo} style={{ height: '150px', borderRadius: '100%', margin: '16px' }} />
      <div className="associations">
        <MembershipTooltip memberships={memberships} />
      </div>
      <h4 style={{ marginTop: 0, marginBottom: 0 }}>
        <Link to={`/employee/${email}`}>{name || employee.name}</Link>
      </h4>
      <p>{role === 'individual contributor' ? 'ind. contributor' : <Link to={`/role/${role}`}>{role}</Link>}</p>
      <hr style={{ width: '75%', margin: '8px auto' }} />
      <p >
        <Link to={`/domain/${domain}`}>{domain}</Link>
      </p>
      <p >
        <Link to={`/squad/${squad}`}>{squad}</Link>
      </p>
      <Conditional condition={timeZone}><p style={timeZoneStyle}><IconTimeClockCircle style={{ marginRight: '4px' }} /> {Intl.DateTimeFormat(assumedLocaleFormat, { timeZone, timeStyle: "short" }).format(Date.now())} local time</p></Conditional>
    </div >
  );
}


export default withTranslation('profilecard')(ProfileCard);
