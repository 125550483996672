import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import { sortBy } from 'lodash';
import tableMaker from '../shared/tablemaker';
import { useSlackData } from '../hooks/useSlackData';

export const SlackActivity = () => {
  const slackData = useSlackData()
  const rooms = sortBy(slackData.rooms || [], (room) => room.roomRank)
  const [data, setData] = useState({ sortKey: { key: 'roomRank', asc: true }, contents: rooms });

  const { columnDefinition, render } = tableMaker({
    data,
    columnNames: [
      'roomRank',
      'channel',
      'connectedMembers',
      'membersConnections',
      'yourMessages',
      'totalMessages',
      'tribeReach',
      'timezoneReach',
      'monitored',
      'isMember',
    ],
    failedMessage: 'No slack data available',
    sort: setData,
  });

  columnDefinition.yourMessages = {
    toolTip: 'Number of times you have posted in this channel in the last two weeks',
    display: '# of your messages',
  };

  columnDefinition.roomRank.display = 'Channel Ranking';
  columnDefinition.connectedMembers.display = '# of members';
  columnDefinition.membersConnections.display = '# of member connections';
  columnDefinition.yourMessages = {
    display: '# of your messages',
    toolTip: 'Number of times you have posted in this channel in the last two weeks',
  };
  columnDefinition.totalMessages = {
    display: '# of messages',
    toolTip: 'Number of times anyone has posted in this channel in the last two weeks',
  };

  columnDefinition.tribeReach = {
    display: 'Domains in channel',
  };

  columnDefinition.timezoneReach = {
    display: 'Timezones in channel',
  };

  columnDefinition.monitored = {
    bool: ({ value }) => value,
  };

  columnDefinition.isMember = {
    bool: ({ value }) => value,
    display: 'In Channel?',
  };

  return render();
};

export default withTranslation('slackActivity')(SlackActivity);
