import { useState } from "react";

export type RenderMessageToasterHandler = (props: {
  message: string;
  type: "info" | "danger" | "success" | "warning";
  rootCorrelationId?: string,
  isDownstreamServiceError?: boolean,
}) => void;

export type ToasterContents = {
  message?: string,
  rootCorrelationId?: string,
  isDownstreamServiceError?: boolean;
}

export default function useMessageToaster() {
  const [toasterContents, setToasterContents] = useState<ToasterContents>({});
  const [toasterType, setToasterType] = useState<"info" | "danger" | "warning" | "success">("info");
  const [showToaster, setShowToaster] = useState<boolean>(false);

  const renderToaster: RenderMessageToasterHandler = ({ message, type, rootCorrelationId, isDownstreamServiceError }) => {
    setShowToaster(true);
    setToasterType(type);
    setToasterContents({ message, isDownstreamServiceError, rootCorrelationId })
  };

  const hideToaster = () => {
    setShowToaster(false);
  };

  return { showToaster, hideToaster, toasterContents, toasterType, renderToaster }
}
