import React from 'react';
import { withTranslation } from 'react-i18next';
import DomainCard from './DomainCard';

import { useDomains } from '../hooks/useDomains';
import { SquibeCard } from './SquibeCard';

const gridStyle = { display: 'grid', gap: '12px', gridTemplateColumns: 'repeat(auto-fit, 200px)' }

/**
 * This displays all the domains on the front page
 * @returns
 */
export function Domains() {
  const { domains, squibes, loading } = useDomains()

  if (loading) {
    return 'Loading...'
  }

  return (
    <div>
      <h2>Domains</h2>
      <div style={gridStyle}>
        {domains.map((domain) => (
          <DomainCard key={domain.id} id={domain.id} />
        ))}
      </div>
      <h2>Ops & Infrastructure Teams</h2>
      <div style={gridStyle}>
        {squibes.map((squibe) => (
          <SquibeCard key={squibe.id} id={squibe.id} />
        ))}
      </div>
    </div >
  );
}


export default withTranslation('domains')(Domains);
