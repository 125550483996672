import { createBrowserHistory } from 'history';

export function parse(search) {
  const params = new URLSearchParams(search);
  return Object.fromEntries(params);
}

export function stringify(obj) {
  const params = new URLSearchParams(obj);
  return params.toString();
}

export function getQueryObject() {
  const browserHistory = createBrowserHistory();
  let { search } = browserHistory.location;
  if (search.charAt(0) === '?') {
    search = search.substr(1, search.length);
  }

  return parse(search);
}

export function setQueryObject(obj) {
  const browserHistory = createBrowserHistory();
  browserHistory.push(`${browserHistory.location.pathname}?${stringify(obj)}`);
}
