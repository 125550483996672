import React from 'react';
import { withTranslation } from 'react-i18next';
import EmployeeDetails from '../shared/EmployeeDetails';
import Squad from '../squads/Squad';
import OrgInfo from '../shared/OrgInfo';
import { useDomain } from '../hooks/useDomain';

export function SimpleDomain({ id }) {
  const { domain, loading } = useDomain(id)
  if (loading || !domain) {
    return null;
  }
  const { squads, leads } = domain;
  return (
    <div>
      <div className="simple-leadership">
        <OrgInfo name="Leadership" />
        <EmployeeDetails members={leads} />
      </div>
      {squads.map((squad) => (
        <div key={`${squad.id}-container`} className="squad simple">
          <div style={{ marginTop: '50px' }} />
          <Squad squadId={squad.id} simple />
        </div>
      ))}
    </div>
  );
}

export default withTranslation('simpleDomain')(SimpleDomain);
