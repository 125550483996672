import { connect } from 'react-redux';
import Partitions from '../presentational/Partitions';
import { setPartition } from '../actions';

// It maps necessary state attributes and dispatchable actions to props for the
// presentational components to consume.

const mapStateToProps = ({ networkReducer: { partition } }) => ({
  partition,
});

const mapDispatchToProps = (dispatch) => ({
  setPartition: (partition) => dispatch(setPartition(partition)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partitions);
