import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import P from 'bluebird';
import { authFetch } from '../shared/auth';
import tableMaker from '../shared/tablemaker';

export const MembershipTypes = ({ currentMembership = [], showCurrentMembership = false, domain } = {}) => {
  const [tableData, setData] = useState({ sortKey: { key: 'name', desc: -1 }, contents: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/memberships`);
        const data = await response.json();

        let memberships = get(data, '_embedded.memberships', []);

        if (domain) {
          memberships = await P.map(
            memberships,
            async (membership) => {
              const employeesResponse = await authFetch(
                `${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/employees?domain=${domain}&membership=${membership.name}`,
              );
              const employees = await employeesResponse.json();
              membership.domainCount = employees._embedded.employees.length;
              return membership;
            },
            { concurrency: 3 },
          );
        }
        setLoading(false);
        setData((value) => ({ sortKey: value.sortKey, contents: memberships }));
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, [domain]);

  const { columnDefinition, render } = tableMaker({
    data: tableData,
    columnNames: ['membershipType', 'name', 'count'],
    failedMessage: 'No memberships found',
    sort: setData,
    loading,
  });

  columnDefinition.membershipType.display = 'Type';

  columnDefinition.name.link = ({ value }) => ({
    href: `/memberships/${value}`,
  });

  columnDefinition.count.display = 'Number of members';

  if (showCurrentMembership || currentMembership.length) {
    columnDefinition.isMember = {
      display: 'Joined?',
      bool: ({ row }) => currentMembership.includes(row.original.name),
    };
  }

  if (domain) {
    columnDefinition.domainCount = {
      display: 'Number in Domain',
    };
  }

  columnDefinition.documentation = {
    link: ({ value }) => ({
      href: value,
      text: 'Learn more',
    }),
    display: 'Learn more',
  };

  return render();
};

export default withTranslation('membershipTypes')(MembershipTypes);
