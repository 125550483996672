import { connect } from 'react-redux';
import { get } from 'lodash';
import NetworkProfile from '../presentational/NetworkProfile';

// It maps necessary state attributes and dispatchable actions to props for the
// presentational components to consume.

const mapStateToProps = ({ networkReducer: { highlighted }, employeeReducer }) => {
  if (highlighted === undefined) {
    return {
      show: false,
    };
  }
  const employee = get(employeeReducer, `employees["${get(employeeReducer, 'current')}"]`);
  return {
    show: true,
    ...employee,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkProfile);
