import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import useMessageToaster from '../messagetoaster/MessageToasterContext';
import { authFetch } from '../shared/auth';
import tableMaker from '../shared/tablemaker';

/**
 * Provides a table view of all the types of software an organization has
 */
export const SoftwareList = ({ domain } = {}) => {
  const [tableData, setData] = useState({ sortKey: { key: 'name', desc: -1 }, contents: [] });
  const [loading, setLoading] = useState(false);
  const renderMessageToaster = useMessageToaster()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await authFetch(`${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/software?domain=${domain}`);
        const resp = await response.json();
        const software = resp._embedded.components.map((sw) => ({
          name: sw.name,
          deprecated: !!sw.deprecated,
          type: sw.type,
          supportContact: sw.supportContact,
          squad: sw._links.squad.name,
          swLink: sw._links.self.href,
        }));
        setData((data) => ({ sortKey: data.sortKey, contents: software }));
        setLoading(false);
      } catch (error) {
        renderMessageToaster({ type: 'error', message: error.message });
        setLoading(false);
      }
    };
    if (domain) {
      fetchData();
    }
  }, [domain, renderMessageToaster]);

  const { columnDefinition, render } = tableMaker({
    data: tableData,
    columnNames: ['type', 'name', 'squad', 'supportContact', 'deprecated'],
    failedMessage: 'No software found',
    loading,
    sort: setData,
    downloadName: `${domain} software`
  });

  columnDefinition.deprecated = {
    bool: ({ value }) => !!value,
    toolTip: 'Is this software still supported by the team? - Indicated by the "deprecated" tag in developer portal.',
  };

  columnDefinition.name.link = ({ row }) => ({
    href: row.original.swLink,
  });

  columnDefinition.squad = {
    link: ({ value }) => ({
      href: `/squad/${value}`,
    }),
    toolTip: 'The email address is taken from the developer portal and matched with a corresponding squad in the org chart.',
  };

  columnDefinition.supportContact = {
    display: 'Support contact',
    toolTip: 'The email address in the contact section of the swagger file is considered the support channel.',
  };

  return render();
};

export default withTranslation('softwareList')(SoftwareList);
