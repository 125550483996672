import { remove } from 'lodash';
import { getQueryObject, setQueryObject } from './shared/qs';

const getQueryString = () => {
  const qs = getQueryObject()

  if (typeof qs.exceptions === 'string') {
    qs.exceptions = [qs.exceptions];
  }

  qs.defaultState = qs.defaultState === undefined || qs.defaultState === 'true';
  qs.partition = qs.partition || 'domain';

  return qs;
};

const modify = (fn) => {
  const qs = getQueryString();
  fn(qs);
  setQueryObject(qs);
};

const setActiveAll = (active) => {
  modify((qs) => {
    qs.defaultState = active;
    delete qs.exceptions;
  });
};

const setPartition = (partition) => {
  modify((qs) => {
    qs.partition = partition;
  });
};

const setNeighbors = (neighbors) => {
  modify((qs) => {
    qs.neighbors = neighbors;
  });
};

const setException = (subject) => {
  modify((qs) => {
    if (!qs.exceptions) {
      qs.exceptions = [];
    }
    if (subject.active !== qs.defaultState) {
      qs.exceptions.push(subject.subject);
    } else {
      remove(qs.exceptions, (exception) => exception === subject.subject);
    }
  });
};

const methods = {
  getQueryString,
  setException,
  setNeighbors,
  setActiveAll,
  setPartition,
};

export default methods;
