const scanActiveElements = ({ svg, edgeModification, nodeModification, textModification, isActive }) => {
  const activeEdges = new Set();
  svg.querySelectorAll('path').forEach((edge) => {
    const edgeActive = isActive(edge);
    if (edgeActive) {
      JSON.parse(edge.getAttribute('email')).forEach((email) => activeEdges.add(email));
    }
    if (edgeModification) {
      edgeModification({ node: edge, active: edgeActive });
    }
  });

  const isNodeActive = (node) => activeEdges.has(node.getAttribute('email'));

  if (nodeModification) {
    svg.querySelectorAll('circle').forEach((node) => {
      nodeModification({ node, active: isNodeActive(node) });
    });
  }

  if (textModification) {
    svg.querySelectorAll('text').forEach((text) => {
      textModification({ node: text, active: isNodeActive(text) });
    });
  }
};

const activeByAttribute = ({ edgeNodes, neighbors, active }) => {
  switch (neighbors) {
    case 'all':
      return edgeNodes.map((value) => active[value]).some((value) => value === true);
    case 'exclude':
      return edgeNodes.map((value) => active[value]).every((value) => value === true);
    case 'intersecting':
      return edgeNodes.map((value) => active[value]).every((value) => value === true) && edgeNodes[0] !== edgeNodes[1];
    default:
      return true;
  }
};

const isEdgeActiveBySelection = ({ svg, edge, sourceClass, partition, neighbors, active }) => {
  const partitionInfo = (className) => {
    const node = svg.querySelector(`circle.${className}`);
    return node.getAttribute(partition);
  };
  const classes = edge.getAttribute('class').split(' ');
  const isTargetEdge = classes.includes(sourceClass);
  const destinationClass = classes.find((className) => className !== sourceClass);
  const activeAtDestination = active[partitionInfo(destinationClass)];

  switch (neighbors) {
    case 'all': {
      return isTargetEdge;
    }
    case 'exclude': {
      return isTargetEdge && activeAtDestination;
    }
    case 'intersecting': {
      return isTargetEdge && activeAtDestination && partitionInfo(sourceClass) !== partitionInfo(destinationClass);
    }

    default:
      return false;
  }
};

module.exports = {
  scanActiveElements,
  activeByAttribute,
  isEdgeActiveBySelection,
};
