/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card } from '@cimpress/react-components';
import { QuestionCircleFill, ChatFill, Slack, DashCircleFill, Globe2, PersonPlusFill } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';

import ProfileCard from '../shared/ProfileCard';
import Conditional from '../shared/Conditional';
import Tabs from '../shared/Tabs';
import { isCurrent } from '../shared/auth';
import SlackConnectivity from '../slack/SlackConnectivity';
import SlackDomainConnectivity from '../slack/SlackDomainConnectivity';
import OrphanedSoftware from '../software/OrphanedSoftware';
import screnshot from '../img/addapp.png';
import MembershipsTypes from '../memberships/MembershipTypes';
import { useEmployee } from '../hooks/useEmployee';
import SlackActivity from '../slack/SlackActivity';

export function Employee() {
  const { email } = useParams();

  const { employee } = useEmployee(email)

  const slackExplanation = (
    <Card className="org-card slack-explanation">
      <h2>Where does this data come from?</h2>
      <p>
        All the data gathered comes from a bot called channelgraph. It scans all public channels in the cimpress workspace to build a
        network representing how different users connect to each other.
      </p>
      <h2>How are the rankings determined?</h2>
      <p>
        Channelgraph attempts to see how valuable a channel is based on the network it built by scanning all of our channels. There are many
        ways a channel can help foster communication across the company - that's the channel's value. If the channel were removed, how much
        less effective might communication at cimpress be? One way a channel can be valuable is if it connects many domains or many
        timezones. Another way is by examining network notions such as
        <a href="https://en.wikipedia.org/wiki/Betweenness_centrality">centrality</a>. If the channel contains members with a high
        centrality and members with a low centrality, the channel is providing a bridge to members of the company that may not have many
        available to them.
      </p>
      <h2>When I look at some of these channels, they seem dead. Why do they still have high rankings?</h2>
      <p>
        Channelgraph has taken a cautious approach to permissions. It's probably not right to add the bot everywhere with no one's consent.
        So there are a lot of channels Where channelgraph cannot see messages. It can only see that the channel exists and who its members
        are. Channelgraph is very optimistic that the channel's members are having a robust conversation (and sometimes it's wrong about
        that) and concludes that all the members are connected to each other. The data it provides probably better represents the channel's
        potential value than its real value, but hope springs eternal!
      </p>
      <h2>I notice channels are missing, even #general isn't there!</h2>
      <p>
        Although channelgraph is optimistic, even it has its limits. If the channel has more than 35 members and it is not in the channel to
        see the activity, there's really no reasonable conclusions it could draw. A channel with 100 people in it likely does not mean all
        100 people are talking to each other. There's nothing it can do but ignore the room and hope that one day it will be invited to join
        the conversation!
      </p>
      <h2>I want better data, how do I add channelgraph to look at my channel?</h2>
      <p>
        You invite channelgraph like any other app. Hopefully this screernshot helps.
        <img alt="how to add an app to your channel" src={screnshot} />
      </p>
    </Card>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '80vh',
        width: '100%',
        marginTop: '20px',
      }}
    >
      <div className="first-column">
        <ProfileCard email={email} />
      </div>
      <div className="user-info">
        <Conditional condition={isCurrent(email)}>
          <Tabs
            tabSet={[
              {
                key: 'Participation',
                name: 'Participation',
                icon: <PersonPlusFill />,
                content: <MembershipsTypes currentMembership={employee?.memberships || []} showCurrentMembership />,
              },
              {
                key: 'Connectivity',
                name: 'Connectivity',
                icon: <ChatFill />,
                toolTip: 'Measures how connected you are vs those in your domain or in your role',
                content: <SlackConnectivity />,
              },
              {
                key: 'DomainConnectivity',
                name: 'Domain Connectivity',
                icon: <Globe2 />,
                toolTip: 'Measures how connected you are to each domain',
                content: <SlackDomainConnectivity />,
              },
              {
                key: 'SlackParticipation',
                name: 'Slack Participation',
                icon: <Slack />,
                toolTip:
                  // eslint-disable-next-line max-len
                  'Channel rankings are made by observing activity and membership in public slack conversations. To improve this data, add the channelgraph app to your public conversations.',
                content: <SlackActivity />,
              },
              {
                key: 'OrphanedSoftware',
                name: 'Orphaned Software',
                icon: <DashCircleFill />,
                toolTip: 'APIs you have access to edit, but do not have contact information associated with any squad.',
                content: <OrphanedSoftware />,
              },
              {
                key: 'About',
                name: 'About Slack Data',
                icon: <QuestionCircleFill />,
                content: <div>{slackExplanation}</div>,
              },
            ]}
          />
        </Conditional>
      </div>
    </div>
  );
}

export default withTranslation('employee')(Employee);
