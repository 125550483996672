import React from 'react';
import { withTranslation } from 'react-i18next';
import { EmbeddedMember } from '../services/responseTypes';
import ProfileCard from './ProfileCard';

const gridStyle = { display: 'grid', gap: '12px', gridTemplateColumns: 'repeat(auto-fit, 194px)' }

export function EmployeeDetails({ members }: { members: EmbeddedMember[] }) {
  return (
    < div style={gridStyle} >
      {
        members.map((member) => (
          <ProfileCard email={member.id} name={member.name} key={member.id} />
        ))
      }
    </div >
  );
}

export default withTranslation('employeeDetails')(EmployeeDetails);
