/* eslint-disable complexity */
import { get } from 'lodash';
import createColorSchemes from './colorschemes';
import { initializeMap } from './valueschema';

const loadValues = (svg) => {
  const readValueSet = (valueTypeName) => {
    const retVal = new Set();
    svg.querySelectorAll('circle').forEach((node) => {
      retVal.add(node.getAttribute(valueTypeName));
    });
    return retVal;
  };

  return initializeMap(readValueSet);
};

const initializeActivePartition = ({ values, valueTypeName, active = true }) => {
  const retVal = {};
  Array.from(values[valueTypeName]).forEach((value) => {
    retVal[value] = active;
  });
  return retVal;
};

const initializeActive = ({ values, active = true }) =>
  initializeMap((valueTypeName) => initializeActivePartition({ values, valueTypeName, active }));

const initialState = {
  values: {},
  colors: {},
  active: {},
  raw: undefined,
  neighbors: 'all',
  partition: undefined,
  highlighted: undefined,
  textState: true,
};

// eslint-disable-next-line max-statements
export default function networkReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'LOAD_NETWORK': {
      const svg = action.data.parsed;
      const values = loadValues(svg);
      const colors = createColorSchemes({ svg, values });
      const active = initializeActive({ values, active: action.qs.defaultState });
      if (action.qs.exceptions && action.qs.exceptions.length) {
        action.qs.exceptions.forEach((exception) => {
          active[action.qs.partition][exception] = !action.qs.defaultState;
        });
      }
      return {
        ...state,
        raw: action.data.raw,
        values,
        active,
        colors,
        neighbors: get(action, 'qs.neighbors', 'all'),
        partition: get(action, 'qs.partition', 'domain'),
      };
    }
    case 'SET_PARTITION': {
      return {
        ...state,
        partition: action.partition,
      };
    }

    case 'SET_COLOR': {
      const newColors = state.colors;
      newColors[state.partition] = { ...newColors[state.partition] };
      newColors[state.partition][action.data.subject] = action.data.color;
      return {
        ...state,
        colors: newColors,
      };
    }

    case 'SET_ACTIVE': {
      const newActives = state.active;
      newActives[state.partition] = { ...newActives[state.partition] };
      newActives[state.partition][action.data.subject] = action.data.active;
      return {
        ...state,
        active: newActives,
      };
    }

    case 'SET_ACTIVE_ALL': {
      const newActives = state.active;

      newActives[state.partition] = initializeActivePartition({
        values: state.values,
        valueTypeName: state.partition,
        active: action.active,
      });
      return {
        ...state,
        active: newActives,
      };
    }

    case 'SET_NEIGHBORS': {
      return {
        ...state,
        neighbors: action.option,
      };
    }

    case 'TOGGLE_HIGHLIGHT': {
      if (state.highlighted === action.data.id) {
        return {
          ...state,
          highlighted: undefined,
        };
      }
      return {
        ...state,
        highlighted: action.data.id,
      };
    }

    case 'SET_TEXT': {
      return {
        ...state,
        textState: action.textState,
      };
    }

    default:
      return state;
  }
}
