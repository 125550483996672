const valueSchema = {
  timezone: {
    partitionable: true,
  },
  squad: {
    partitionable: true,
  },
  name: {
    partitionable: false,
  },
  role: {
    partitionable: true,
  },
  domain: {
    partitionable: true,
    initial: true,
  },
};

const scanPartitionable = (fn) => {
  Object.keys(valueSchema)
    .filter((valueTypeName) => valueSchema[valueTypeName].partitionable)
    .forEach((valueTypeName) => fn({ valueTypeName, valueType: valueSchema[valueTypeName] }));
};

const initializeMap = (fn) => {
  const retVal = {};
  Object.keys(valueSchema).forEach((valueTypeName) => {
    retVal[valueTypeName] = fn(valueTypeName);
  });
  return retVal;
};

module.exports = {
  scanPartitionable,
  initializeMap,
};
