import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PersonFill, ChatFill, ClipboardFill, PeopleFill, CloudFill, PersonPlusFill } from 'react-bootstrap-icons';
import { OrgInfo } from '../shared/OrgInfo';
import Tabs from '../shared/Tabs';
import { ColumnarCard } from '../shared/ColumnarCard';
import EmployeeDetails from '../shared/EmployeeDetails';
import EmployeeList from '../shared/EmployeeList';
import { SlackDomainToDomain } from '../slack/SlackDomainToDomain';
import { MembershipTypes } from '../memberships/MembershipTypes';
import { SoftwareList } from '../software/SoftwareList';
import { useDomain } from '../hooks/useDomain';

/**
 * The "full" domain displays all possible information about a domain, unlike the simple view which is intended to be embedded in iFrames
 */
export function FullDomain({ id }) {
  const { domain, loading } = useDomain(id)
  if (loading || !domain) {
    return null;
  }
  const { squads, members, leads, name, distributionList } = domain;

  return (
    <>
      <OrgInfo name={name} distributionList={distributionList} documentation="" />

      <div className="domainInfo">
        <Tabs
          tabSet={[
            {
              key: 'Leadership',
              name: 'Leadership',
              icon: <ClipboardFill />,
              content: <EmployeeDetails members={leads} />,
            },
            {
              key: 'Squads',
              name: 'Squads',
              icon: <PeopleFill />,
              content: (
                <ColumnarCard columnSize={4}>
                  {squads.map((squad) => (
                    <p key={squad.id}>
                      <Link to={`/squad/${squad.id}`}>{squad.id}</Link>
                    </p>
                  ))}
                </ColumnarCard>
              ),
            },
            {
              key: 'People',
              name: 'People',
              icon: <PersonFill />,
              content: <EmployeeList header="" members={members} shuffleLink={`/employee/random?domain=${id}`} />,
            },
            {
              key: 'Participation',
              name: 'Participation',
              icon: <PersonPlusFill />,
              content: <MembershipTypes domain={id} />,
            },
            {
              key: 'Connectivity',
              name: 'Connectivity',
              icon: <ChatFill />,
              content: <SlackDomainToDomain domain={id} />,
            },
            {
              key: 'Software',
              name: 'Software',
              icon: <CloudFill />,
              content: <SoftwareList domain={id} />,
            },
          ]}
        />
      </div>
    </>
  );
}

export default withTranslation('fullDomain')(FullDomain);
