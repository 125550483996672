import * as ReactDOMClient from 'react-dom/client';
import './scss/styles.scss';
import store from './reducers';
import application from './Application';

const root = ReactDOMClient.createRoot(document.getElementById('root'));

application({
  root,
  store,
});
