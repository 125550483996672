import React from 'react';
import { IconAlertTriangle, IconCheck } from '@cimpress-technology/react-streamline-icons';
import { colors, Tooltip } from '@cimpress/react-components';
import { useSoftware } from '../hooks/useSoftware';


export default function OwnershipPanel({ organizationName, organizationType = 'squad' }) {
  const { softwareComponents, loading } = useSoftware(organizationName, organizationType);

  const loader = <h1 style={{ padding: '10px' }}>Loading . . .</h1>;

  return loading ? (
    loader
  ) : (
    <>
      {softwareComponents.map(component => {
        return (
          <div className="apis">
            <h3 id={component.name}>{component.name}</h3>
            {
              component.values.map(
                ({
                  id,
                  name,
                  _links: {
                    self: { href },
                  },
                  _embedded: {
                    warnings
                  }
                }) => {
                  const content = (
                    <a target="_blank" rel="noopener noreferrer" href={href}>
                      {name}
                    </a>
                  )
                  if (warnings.length) {
                    return (
                      <p key={id} className="warning">
                        <Tooltip direction="right" contents={warnings.map(
                          warning => (<a className="fix" target="_blank" rel="noopener noreferrer" href={warning._links.fix.href}>{warning.message}</a>))}
                          tooltipInnerStyle={{ padding: '10px' }}>
                          <IconAlertTriangle weight="fill" style={{ color: colors.warning.base }} />
                        </Tooltip>
                        {content}
                      </p>)
                  }
                  return (
                    <p key={id} className="warning">
                      <IconCheck />
                      {content}
                    </p>)

                },
              )
            }
          </div>)
      })}
    </>
  )
}
