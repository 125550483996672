import React from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMembership } from '../hooks/useMembership';
import Conditional from '../shared/Conditional';
import MemberList from '../shared/MemberList';
import { OrgInfo } from '../shared/OrgInfo';
import { OrganizationMetaInfo } from '../software/OrganizationMetaInfo';

export function Membership() {
  const { membership: membershipName } = useParams();
  const { membership, loading } = useMembership(membershipName)
  if (loading || !membership) {
    return null;
  }

  const isOpenSourceCommunity = membership.membershipType === 'internal open source community'

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isOpenSourceCommunity ? 'auto 1fr' : '1fr',
        gap: '24px'
      }}
    >
      <Conditional condition={isOpenSourceCommunity}>
        <OrganizationMetaInfo organizationName={membership.name} organizationType="membership" />
      </Conditional>
      <div>
        <OrgInfo name={membership.name} distributionList={membership.distributionList} documentation={membership.documentation} />
        <MemberList members={membership.members} />
      </div>
    </div>
  );
}

export default withTranslation('membership')(Membership);
