import { configureStore } from '@reduxjs/toolkit';
import networkReducer from './network/reducer';

// This is a top level object containing all reducers throughout the app.
// When authoring a new reducer make sure to import it here and add it
// to the default object.  This will help keep a clear list of reducers
// and will keep the 'createStore' call clean in index.jsx when we bootstrap.

const reducer = {
  networkReducer,
};

const store = configureStore({ reducer });

export default store;
