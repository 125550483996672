import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { get, orderBy } from 'lodash';
import tableMaker from '../shared/tablemaker';
import roundHundreths from '../shared/roundhundredths';
import { fetchDomainConnectivity } from '../services/channelgraph';

export const SlackDomainConnectivity = () => {
  const [data, setData] = useState({ contents: [] });

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchDomainConnectivity();

      setData({
        contents: orderBy(get(result, '_embedded.domainconnections', []), (domainConnections) => domainConnections.relations, 'desc'),
      });
    };

    fetchData();
  }, []);

  const { columnDefinition, render } = tableMaker({
    data,
    columnNames: ['domain', 'relations'],
    failedMessage: 'No slack data available',
  });

  columnDefinition.relations = {
    toolTip: 'The percentage of people in this domain you are connected to',
    display: 'Your Connectivity',
    cell: (row) => roundHundreths(row.original.relations),
  };

  return render();
};

export default withTranslation('slackDomainConnectivity')(SlackDomainConnectivity);
