import React from 'react';
import { withTranslation } from 'react-i18next';
import { PersonCheckFill } from 'react-bootstrap-icons';
import { Tooltip } from '@cimpress/react-components';

export function MembershipTooltip({ memberships }) {
  if (!memberships.length) {
    return null;
  }

  const contents = memberships.map((membership) => (
    <div>
      <a style={{ color: 'white' }} href={`/memberships/${membership}`}>
        {membership}
      </a>
    </div>
  ));
  return (
    <Tooltip direction="bottom" contents={contents} tooltipInnerStyle={{ padding: '10px' }}>
      <PersonCheckFill />
    </Tooltip>
  );
}

export default withTranslation('membership')(MembershipTooltip);
