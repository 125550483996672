/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react';

export default function Shimmer({ height = '14px', width = '75%', borderRadius = '4px', style = {} }: {
  height?: string,
  width?: string,
  borderRadius?: string,
  style?: CSSProperties
}) {
  return <div className="shimmer" style={{ margin: '4px auto', height, width, borderRadius, ...style }} />
}
