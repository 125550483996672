import Auth from '@cimpress-technology/auth-js';
import EnhancedError from './EnhancedError';


const auth = new Auth('2zkgq29zn3p75QbNaor82fc2XtCnObtO', '/');

export { auth };

export function authFetch(url, query) {
  const qs = new URLSearchParams(query);
  return fetch(query ? `${url}?${qs}` : url, {
    method: 'GET',
    headers: auth.getJsonHeaders({ omitCimpressIdTokenHeader: true }),
  });
}

export function authFetchWithHeaders({ url, headers }) {
  return fetch(url, {
    method: 'GET',
    headers: Object.assign(headers, auth.getJsonHeaders({ omitCimpressIdTokenHeader: true })),
  });
}

export function loggedInUserEmail() {
  return auth.getProfile()['https://claims.cimpress.io/email'].toLowerCase();
}

export function isCurrent(email) {
  return email !== undefined && auth.getProfile()['https://claims.cimpress.io/email'].toLowerCase() === email.toLowerCase();
}

export async function handleErrors(response) {
  if (response.ok === false) {
    const { message, additionalData } = await response.json();
    if (window.newrelic) {
      // This is specifically for new relic since nr browser has limited support for extended errors
      const nrError = new Error(
        `${message || `${response.status}: ${response.statusText}`}${additionalData ? `: ${JSON.stringify(additionalData)}` : ""
        }`
      );
      window.newrelic.noticeError(nrError, {
        user: auth.getProfile().email,
        prefLanguage: window.navigator.language,
        prefLanguages: window.navigator.languages.join(", "),
      });
    }
    throw new EnhancedError(
      message || `${response.status}: ${response.statusText}`,
      response.status,
      response.statusText,
      {
        message,
        ...additionalData,
      }
    );
  }

  return response;
}
