import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TextField } from '@cimpress/react-components';
import EmployeeDetails from '../shared/EmployeeDetails';
import { useEmployeeSearch } from '../hooks/useEmployeeSearch';
import useDebounce from '../hooks/useDebounce';

export function Search() {
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500)
  const { employees } = useEmployeeSearch({ search: debouncedSearch })

  return (
    <div>
      <TextField name="required" label="Name" value={searchText} onChange={e => setSearchText(e.target.value)} required />
      <EmployeeDetails members={employees || []} name="something" />
    </div>
  );
}

export default withTranslation('search')(Search);
