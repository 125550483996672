import useSWR from 'swr';
import { startCase } from 'lodash';
import { fetchMembership } from '../services/orgchart';

export function useMembership(membershipName: string) {
  const { data, error, isLoading } = useSWR(`/v0/memberships/${membershipName}`, () => fetchMembership(membershipName));

  let membership;
  if (data) {
    const { dl, name, membershipType, documentation, _embedded: { members }, } = data;
    membership = {
      distributionList: dl, name: startCase(name), members, documentation, membershipType
    }
  }

  return {
    membership,
    loading: isLoading,
    error,
  }
}