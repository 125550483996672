import React from 'react';
import { withTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import EmployeeDetails from './EmployeeDetails';
import { EmbeddedMember } from '../services/responseTypes';

export function MemberList({ members }: { members: EmbeddedMember[] }) {
  const employees = sortBy(
    members.filter((member) => member.name),
    (member) => {
      const names = member.name.split(' ');
      return names[names.length - 1];
    },
  );

  // TODO reinstate role priority/sorting

  return (
    <EmployeeDetails members={employees} />
  );
}

export default withTranslation('memberList')(MemberList);
