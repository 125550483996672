/* eslint-disable react/require-default-props */
// TODO react and typescript aren't getting along...
import React from 'react';
import { Snackbar } from '@cimpress/react-components';
import { withTranslation } from 'react-i18next';
import { ToasterContents } from './useMessageToaster';

function ErrorContent({ message = '', rootCorrelationId = '', isDownstreamServiceError = false, t }: {
  message?: string, rootCorrelationId?: string, isDownstreamServiceError?: boolean, t: any
}) {
  let details;
  let rootCorrelationIdContent;
  let apologiesMessage;

  if (rootCorrelationId) {
    rootCorrelationIdContent = (
      <span>
        {t('rootIdLabel')}
        {rootCorrelationId}
      </span>
    );
  }

  if (rootCorrelationId) {
    details = (
      <div className="re-messageToasterFlex re-errorDetails">
        {t('detailsLabel')}
        {message}
        {rootCorrelationIdContent}
      </div>
    );
  }

  // only display a helpful retry message if we're surfacing an error from a downstream service
  if (isDownstreamServiceError) {
    const email = 'TuringSquad@cimpress.com';
    apologiesMessage = (
      <p>
        {t('apologiesMessage1')}
        <br />
        {t('apologiesMessage2')}
        <a className="re-errorDetails re-errorLink" href={`mailto:${email}`}>
          {email}
        </a>
        .
      </p>
    );
  }

  return (
    <div className="re-messageToasterFlex">
      <h5>{message}</h5>
      {apologiesMessage}
      {details}
    </div>
  );
}


export function MessageToaster({
  showToaster = false, hideToaster, toasterContents, toasterType, t
}: { showToaster: boolean, hideToaster: () => void, toasterContents: ToasterContents, toasterType: "info" | "danger" | "success" | "warning", t: any }) {
  if (toasterType !== 'danger') {
    return (
      <div>
        <Snackbar show={showToaster} onHideSnackbar={hideToaster} delay={5000}>
          <p>{toasterContents.message}</p>
        </Snackbar>
      </div>
    );
  }
  return (
    <Snackbar show={showToaster} onHideSnackbar={hideToaster}>
      <ErrorContent
        t={t}
        message={toasterContents.message}
        rootCorrelationId={toasterContents.rootCorrelationId}
        isDownstreamServiceError={toasterContents.isDownstreamServiceError}
      />
    </Snackbar>
  );
}

export default withTranslation('messageToaster')(MessageToaster);
