import useSWR from 'swr'
import { groupBy } from 'lodash'
import { fetchSoftware } from '../services/orgchart'

const mapping = {
  gitLabProject: {
    name: "Repositories",
    position: 3
  },
  api: {
    name: "Services",
    position: 0
  },
  table: {
    name: "Data Sets",
    position: 1
  },
  ui: {
    name: "User Interfaces",
    position: 2
  },
}

const mapType = (key: string) => {
  // appeasing typescript with specificity
  if (key === "gitLabProject" || key === "api" || key === "table" || key === "ui") {
    return mapping[key];
  }
  return {
    name: key,
    position: Object.values(mapping).length,
  }
}


export function useSoftware(organizationName: string, organizationType = "squad") {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/software?${organizationType}=${organizationName}`,
    () => fetchSoftware(organizationType, organizationName)
  );

  let softwareComponents;
  if (data) {
    const grouped = groupBy(data._embedded.components, component => component.type);
    const orderedComponents = Object.keys(grouped).map(key => {
      const mapped = mapType(key);

      return {
        position: mapped.position,
        name: mapped.name,
        values: grouped[key]
      }
    })
    orderedComponents.sort((a, b) => {
      return a.position >= b.position ? 1 : -1
    })
    softwareComponents = orderedComponents;
  }

  return {
    softwareComponents,
    loading: isLoading,
    error,
  }
}
