import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown } from '@cimpress/react-components';

export function Partitions({ partition, setPartition }) {
  return (
    <Dropdown title={`Color by ${partition || ' ...'}`}>
      <button type="button" onClick={() => setPartition('domain')}>
        Domain
      </button>
      <button type="button" onClick={() => setPartition('squad')}>
        Squad
      </button>
      <button type="button" onClick={() => setPartition('timezone')}>
        Timezone
      </button>
      <button type="button" onClick={() => setPartition('role')}>
        Role
      </button>
    </Dropdown>
  );
}

export default withTranslation('partitions')(Partitions);
