import React from 'react';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Dropdown } from '@cimpress/react-components';

const neighborStrings = {
  all: 'Show all',
  exclude: "Don't show neighbors",
  intersecting: 'Show only intersections',
};

export function Edges({ neighbors, changeNeighbors }) {
  return (
    <Dropdown title={`Edges: ${get(neighborStrings, neighbors, '...')}`}>
      <button type="submit" onClick={() => changeNeighbors('all')}>
        {neighborStrings.all}
      </button>
      <button type="submit" onClick={() => changeNeighbors('exclude')}>
        {neighborStrings.exclude}
      </button>
      <button type="submit" onClick={() => changeNeighbors('intersecting')}>
        {neighborStrings.intersecting}
      </button>
    </Dropdown>
  );
}

export default withTranslation('network')(Edges);
