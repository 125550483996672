import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ColumnarCard } from './ColumnarCard';

export function EmployeeList({ header = 'Members', members, shuffleLink, headerLink }) {
  const headerElement = headerLink ? <Link to={headerLink}>{header}</Link> : header;

  return (
    <ColumnarCard columnSize={4} header={headerElement} shuffleLink={shuffleLink}>
      {members.map((member) => (
        <p id={member.id} style={{ width: '80%' }} key={member.id}>
          <Link to={`/employee/${member.id}`}>{member.name}</Link>
        </p>
      ))}
    </ColumnarCard>
  );
}

export default withTranslation('employeelist')(EmployeeList);
