import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { get, orderBy } from 'lodash';
import tableMaker from '../shared/tablemaker';
import roundHundreths from '../shared/roundhundredths';
import { fetchSquadToSquadConnectivity } from '../services/channelgraph';

export const SlackSquadToSquad = ({ squad }) => {
  const [data, setData] = useState({ contents: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchSquadToSquadConnectivity(squad.toLowerCase());
        setLoading(false);

        setData({
          contents: orderBy(get(result, '_embedded.connectivity', []), (squadConnections) => squadConnections.connectionPct, 'desc'),
        });
      } catch (err) {
        setLoading(false);
      }
    };

    if (squad) {
      fetchData();
    }
  }, [squad]);

  const { columnDefinition, render } = tableMaker({
    data,
    columnNames: ['targetSquad', 'connectionPct'],
    failedMessage: 'No slack connectivity found',
    loading,
  });

  columnDefinition.targetSquad = {
    display: 'Squad',
    link: ({ value }) => ({
      href: `/squad/${value}`,
    }),
  };

  columnDefinition.connectionPct = {
    toolTip: 'The percentage of possible connections that are active between these squads',
    display: "Squads's Connectivity",
    link: ({ row, value }) => ({
      // eslint-disable-next-line max-len
      href: `/network?defaultState=false&partition=squad&exceptions=${squad.toLowerCase()}&exceptions=${row.original.targetSquad.toLowerCase()}&neighbors=intersecting`,
      value: roundHundreths(value),
    }),
  };

  return render();
};

export default withTranslation('slackSquadToSquad')(SlackSquadToSquad);
