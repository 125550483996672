import useSWR from 'swr'
import { loadSlackActivity } from '../services/channelgraph';

export function useSlackData() {
  const { data, error, isLoading } = useSWR(`v0/userreports/me/rooms`, loadSlackActivity);

  let rooms;
  if (data) {
    rooms = data;
  }

  return {
    rooms,
    loading: isLoading,
    error,
  }
}
