import { connect } from 'react-redux';
import NetworkOptions from '../presentational/NetworkOptions';
import { setColor, setActive, setActiveAll } from '../actions';

// It maps necessary state attributes and dispatchable actions to props for the
// presentational components to consume.

const mapStateToProps = ({ networkReducer: { partition, colors, active } }) => ({
  active: active[partition] || {},
  colors: colors[partition] || {},
});

const mapDispatchToProps = (dispatch) => ({
  changeColor: (data) => dispatch(setColor(data)),
  changeActive: (data) => dispatch(setActive(data)),
  changeActiveAll: (active) => dispatch(setActiveAll(active)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkOptions);
