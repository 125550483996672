/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { CSVLink } from 'react-csv';
import { authFetchWithHeaders } from './auth';

function Download() {
  const [data, setData] = useState({ org: [] });
  const csvLink = useRef(null);
  return (
    <>
      <Link
        to="#"
        onClick={async () => {
          const response = await authFetchWithHeaders({
            url: `${process.env.REACT_APP_ORGCHART_ADDRESS}/v0/employees`,
            headers: {
              Accept: 'text/csv',
            },
          });
          const result = await response.text();
          setData({
            org: result,
          });
          csvLink.current.link.click();
        }}
      >
        Download
      </Link>

      <CSVLink data={data.org} filename="organization.csv" className="hidden" ref={csvLink} target="_blank" />
    </>
  );
}

export default Download;
