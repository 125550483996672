import React from 'react';
import { withTranslation } from 'react-i18next';
import { EnvelopeFill, InfoSquareFill } from 'react-bootstrap-icons';
import Conditional from './Conditional';

export function OrgInfo({ name, distributionList, documentation }) {
  return (
    <div className="org-info">
      <div>
        <p className="title">{name}</p>
        <Conditional condition={distributionList || documentation}>
          <p className="links">
            {distributionList ? (
              <span>
                <EnvelopeFill size={18} />
                <a href={`mailto:${distributionList}`}>{distributionList}</a>
              </span>
            ) : null}
            {documentation ? (
              <span>
                <InfoSquareFill size={18} />
                <a href={documentation}>{documentation}</a>
              </span>
            ) : null}
          </p>
        </Conditional>
      </div>
    </div>
  );
}

export default withTranslation('orgInfo')(OrgInfo);
